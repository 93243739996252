@media (max-width: 767px) {
  html {
    body {
      &.open-catalog {
        overflow: hidden;
      }
    }
  }

  .open-open-catalog {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #433430;
    margin-top: -32px;
    margin-bottom: 32px;
    cursor: pointer;

    div {
      padding-right: 20px;
      background: url($pathImg+'/icon/right-arrow-mobile.svg') no-repeat center right;
      background-size: 12px 11px;
    }

    &:hover {
      color: #d97447;
      div {
        background: url($pathImg+'/icon/right-arrow-mobile_active.svg') no-repeat center right;
        background-size: 12px 11px;
      }
    }


  }

  .container-main {
    width: 100%;

    .left-column {
      border-radius: 0;
      position: fixed;
      z-index: 200;
      top: -100%;
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
      margin-top: 0;
      border: none;
      border-radius: 0;
      -webkit-transition: top 0.2s ease-in-out;
      -moz-transition: top 0.2s ease-in-out;
      -ms-transition: top 0.2s ease-in-out;
      -o-transition: top 0.2s ease-in-out;
      transition: top 0.2s ease-in-out;

      .left_accordion {
        padding: 0 0 20px 0;
      }

      .close-open-catalog {
        background: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        cursor: pointer;

        span {
          font-size: 16px;
          font-weight: 500;
          color: #d97447;
        }
      }
    }
  }

  .open-catalog {
    .container-main {
      .left-column {
        top: 0;
        background: rgba(0, 0, 0, 0.54);
        height: 100%;

        .left_accordion {
          background: #ffffff;

          >li {
            margin: 0 15px;
            padding: 0;
            border-bottom: 1px solid #d8d8d8;

            >a {
              padding: 0 0 0 19px;
              margin: 20px 0 20px 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .category_page {
    max-width: 360px;
  }
}
