@media (max-width: 767px) {
  #footerMenu {
    justify-content: space-between;

    >li {
      a {
        padding-top: 27px;
        display: block;
      }

      &:nth-child(1) {
        a {
          background: url($pathImg+'/icon/footerMenu1.svg') no-repeat center 4px;
        }
      }

      &:nth-child(2) {
        a {
          background: url($pathImg+'/icon/footerMenu2.svg') no-repeat center 4px;
        }
      }

      &:nth-child(3) {
        a {
          background: url($pathImg+'/icon/footerMenu3.svg') no-repeat center 4px;
        }
      }

      &:nth-child(4) {
        a {
          background: url($pathImg+'/icon/footerMenu4.svg') no-repeat center 4px;
        }
      }

      &:nth-child(5) {
        a {
          background: url($pathImg+'/icon/footerMenu5.svg') no-repeat center 4px;
        }
      }

      &.active {
        &:nth-child(1) {
          a {
            background: url($pathImg+'/icon/footerMenu1_active.svg') no-repeat center 4px;
          }
        }

        &:nth-child(2) {
          a {
            background: url($pathImg+'/icon/footerMenu2_active.svg') no-repeat center 4px;
          }
        }

        &:nth-child(3) {
          a {
            background: url($pathImg+'/icon/footerMenu3_active.svg') no-repeat center 4px;
          }
        }

        &:nth-child(4) {
          a {
            background: url($pathImg+'/icon/footerMenu4_active.svg') no-repeat center 4px;
          }
        }

        &:nth-child(5) {
          a {
            background: url($pathImg+'/icon/footerMenu5_active.svg') no-repeat center 4px;
          }
        }
      }
    }
  }

  .footer-menu-container {
    display: block;
    height: 46px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    width: 100%;
    z-index: 100;
    font-size: 11px;
    font-weight: bold;
    box-shadow: 0px -5px 8px 1px rgba(0, 0, 0, 0.41);
    padding-top: 3px;
    height: 47px;

    .active {
      a {
        color: #d97447;
      }
    }
  }
}
