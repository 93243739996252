.mock-page {
  .row{
    >div{
      margin-bottom: 16px;
    }
  }
  .page-header {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.4px;
    text-align: center;
    color: $greyishA;
    margin-bottom: 32px;
    b {
      font-weight: 500;
    }
  }
  .row {
    a {
      width: 100%;
    }
  }
  .mock-image {
    width: 100%;
    background: url($pathImg+'/icon/mock.svg') no-repeat center center;
    background-size: 886px 327px;
    height: 350px;
  }
  .mock-image-error {
    margin-top: 40px;
    width: 100%;
    background: url($pathImg+'/icon/mock-error.svg') no-repeat center center;
    background-size: contain;
    height: 270px;
  }
}