.feedback-page {
  display: flex;
  .left-block {
    h1 {
      font-size: 22px;
      font-weight: bold;
      color: $greyishA;
      margin: 0 0 24px 0;
    }
    .sm-block {
      margin-bottom: 24px;
      div {
        font-size: 14px;
        color: $greyish;
        line-height: 16px;
        a {
          font-size: 14px;
          color: $greyish;
          line-height: 24px;
        }
      }
      h2 {
        font-size: 17px;
        font-weight: 500;
        color: $dull-orange;
      }
    }
  }
}