@media (max-width: 767px) {
  .modal-form {
    .modal-content {
      .modal-body {
        padding: 0 16px 16px 16px;
      }
    }
  }

  .mob-none {
    display: none;
  }

  .horeca-page {
    h1 {
      font-size: 46px;
      font-weight: bold;
      text-align: center;
      line-height: normal;
    }

    .horeca-page-header {
      .horeca-page-header-block {
        .row-hor {
          padding: 40px 8px;
          height: 100%;

          .horeca-page-header-text {
            text-align: center;
          }

          .enums-horeca-page-header {
            margin-top: 40px;
            font-size: 20px;

            >div {
              padding-bottom: 14px;
            }
          }
        }
      }
    }

    .horeca-page-sec2 {
      padding-top: 0;

      .row>div {
        height: 100%;
      }

      .block-hotel {
        .block-image {
          height: 320px;
        }

        .desc {
          position: static;
          opacity: 1;
          color: $greyish;
          font-size: 17px;
          text-align: left;

          .text {
            max-width: 100%;
          }
        }

        &:hover {
          .info {
            opacity: 1;
          }

          .desc {
            background: transparent;
          }
        }
      }
    }
  }

  .repair-page {
    .repair-page-header {
      .repair-page-header-block {
        .row-hor {
          padding: 40px 8px;
          height: 100%;

          h1 {
            font-size: 46px;
            font-weight: bold;
            text-align: center;
            line-height: normal;
            margin-bottom: 10px;
          }

          .repair-page-header-text {
            text-align: center;
            margin-bottom: 32px;
            font-size: 20px;
          }

          .repair-button-container {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .rent-page {
    .flex-table {
      .flex-tr-header {
        display: none;
      }

      .flex-tr {
        border: none;
        flex-direction: column;

        .flex-td {
          border: none;
          padding: 10px 0;

          &:first-child {
            flex: 0 1 auto;
            font-size: 17px;
          }
        }

        &:last-child {
          border-bottom: none;

          .flex-td {
            background: transparent;
          }
        }
      }
    }

    .rent-page-header {
      .rent-page-header-block {
        .row-hor {
          padding: 40px 8px;
          height: 100%;

          h1 {
            font-size: 46px;
            font-weight: bold;
            text-align: center;
            line-height: normal;
            margin-bottom: 10px;
          }

          .turnkey-page-header-text {
            text-align: center;
            margin-bottom: 32px;
            font-size: 20px;
          }

          .rent-button-container {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .turnkey-page {
    .turnkey-photo-list {
      .turnkey-photo {
        padding-bottom: 16px;
      }
    }

    .turnkey-page-header {
      .turnkey-page-header-block {
        .row-hor {
          padding: 40px 8px;
          height: 100%;

          h1 {
            font-size: 46px;
            font-weight: bold;
            text-align: center;
            line-height: normal;
            margin-bottom: 10px;
          }

          .turnkey-page-header-text {
            text-align: center;
            margin-bottom: 32px;
            font-size: 20px;
          }

          .turnkey-button-container {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {

  .repair-page-header-block,
  .horeca-page-header-block,
  .rent-page-header-block,
  .turnkey-page-header-block {
    padding-right: 0;
    padding-left: 0;
  }

  .type-machines-img {
    margin-left: -8px;
    margin-right: -8px;
  }

  .horeca-page {
    .horeca-page-sec2 {
      .block-hotel {
        .block-image {
          margin-left: -8px;
          margin-right: -8px;
        }
      }
    }
  }
}
