@media (max-width: 991px) {
  .extra_fields_block {
    .list_specifications {
      .block_specifications {
        width: 100%;
      }
    }
  }

  #block_attr_sel_2 {
    .input_type_radio {
      width: 100%;
    }
  }

  .product-page {
    .product-main-block {
      .image-block-border {
        .image-block {
          img {
            max-width: 360px;
          }
        }
      }

      // .action-block {
      // .buttons {
      //   button {
      //     width: 100%;
      //   }
      // }

      // .prod_buttons {
      //   flex-wrap: wrap;
      //   justify-content: center;

      //   .prod_qty_input {
      //     width: 100%;
      //     box-sizing: border-box;
      //     height: 34px;

      //     .qty {
      //       width: 100%;
      //       display: flex;
      //       align-items: center;

      //       input {
      //         line-height: 25px;
      //       }
      //     }
      //   }

      //   .summary_price {
      //     margin: 25px 0;
      //   }
      // }
      // }
    }
  }
}

@media (max-width: 767px) {
  .previous-page {
    display: block;
    margin-bottom: 32px;

    a {
      width: 100%;
    }
  }

  .related_container {
    max-width: 360px;
  }

  .product-page {
    max-width: 360px;
    margin: 0 auto;

    .modal-content .modal-body .order-form {
      width: 100%;
    }

    .product-block-description {
      margin: 0 auto;
      margin-top: 15px;
    }

    .product-main-block {
      flex-wrap: wrap;
      justify-content: center;

      .image-block-border {
        margin-right: 0;
        width: 100%;

        .image-block img {
          width: 100%;
        }
      }

      .action-block {
        margin-top: 32px;

        .prod_attributes {
          .attributes_title {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .input_type_radio {
    label {
      line-height: 24px;
      margin-bottom: 16px;

      span {
        display: block;
        line-height: 24px;
      }

    }

    input+label {
      padding: 0 0 0 30px;

      &:before {
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
      }

      &:after {
        top: 5px;
        left: 5px;
        width: 14px;
        height: 14px;
      }
    }
  }

}

@media (max-width: 575px) {
  .top-product-block {
    .top-product-list {
      .top-item {
        margin-bottom: 2rem;

        .category_name {
          a {
            font-size: 16px;
            line-height: 50px;
          }
        }

        .product_image {
          border: 1px solid #ececec;
        }
      }
    }
  }

}
