.container-main {
  max-width: 1056px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  .left-column {
    width: 232px;
    min-width: 232px;
    margin-top: -32px;
    margin-right: 16px;
    background-color: $white-two;
    min-height: calc(100vh - 269px);
  }
}

.previous-page {
  display: none;
}

.category_page {
  .category_description {
    font-size: 14px;
    line-height: 1.71;
    color: #424242;
    margin: 25px 0;
  }
  h1 {
    color: #433430;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .category_list_category {
    >div {
      padding-top: 16px;
      padding-bottom: 16px;
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
      }
    }
    .product_block_cat {
      height: 100%;
      .product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .product_image {
          width: 100%;
          border: 1px solid $white;
          a {
            display: block;
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
        .product_name {
          font-size: 17px;
          font-weight: 500;
          color: $greyish;
          margin-top: 12px;
          overflow: hidden;
          max-height: 50px;
        }
        .product_composition {
          margin-top: 5px;
        }
        .product_weight {
          margin-top: 5px;
        }
        .title_property {
          font-size: 12px;
          color: #8f8f8f;
          display: inline-block;
          vertical-align: top;
        }
        .value_property {
          font-size: 12px;
          color: $greyish;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          vertical-align: top;
          max-width: 175px;
        }
        .product_price_block {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 10px;
          .default_price {
            background-color: #d97447;
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            padding: 3px 15px;
            display: inline-block;
            .full_number {
              font-size: 17px;
            }
          }
          .no_old_price {
            font-size: 14px;
            font-weight: 500;
            color: $dull-orange;
            padding: 3px 15px;
            display: inline-block;
            .full_number {
              font-size: 17px;
            }
          }
          .old_price {
            font-size: 14px;
            border: 1px solid #d97447;
            font-weight: 500;
            color: #8f8f8f;
            padding: 3px 15px;
            display: inline-block;
            position: relative;
            .full_number {
              font-size: 17px;
            }
            .line {
              height: 1px;
              background: #8f8f8f;
              position: absolute;
              top: 15px;
              left: 8px;
              right: 8px;
            }
          }
        }
      }
    }
  }
}

.left_accordion {
  list-style-type: none;
  padding: 28px 0;
  a {
    margin-bottom: 10px;
    display: inline-block;
  }
  >li {
    padding-right: 10px;
    >ul {
      list-style-type: none;
      padding-left: 25px;
      a {
        font-size: 14px;
        color: $greyishA;
        &.active {
          font-weight: bold;
          color: $dull-orange;
        }
      }
    }
    >a {
      color: #8f8f8f;
      font-size: 16px;
      font-weight: 500;
      padding: 1px 10px 1px 10px;
      display: inline-block;
      width: 100%;
      &.parent {
        background: url($pathImg+'/icon/ldr-b.svg') no-repeat center right;
        background-size: 12px 8px;
        &[aria-expanded=true],
        &.collapse:not(.collapsed),
        &.active {
          background: url($pathImg+'/icon/ldr-t.svg') no-repeat center right;
          background-size: 12px 8px;
        }
      }
      &.active {
        border-left: 4px solid $dull-orange;
        padding: 0 0 0 15px;
        font-size: 16px;
        font-weight: bold;
        color: $greyishA;
      }
    }
  }
}

.open-open-catalog,
.close-open-catalog {
  display: none;
}

@import 'joomcatMobile';