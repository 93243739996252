.main-first-block {
  .block-content {
    position: relative;
    display: block;
    cursor: pointer;
    .btn-lnk-w {
      .lnk {
        color: $wh;
        white-space: nowrap;
      }
    }
    &:hover {
      .btn-lnk-w {
        .lnk {
          background-color: $wh;
          width: 94px;
          padding: 0 10px;
          color: $greyish;
        }
        .icon {
          width: 0;
        }
      }
    }
    &.block-content-0 {
      width: 496px;
      height: 412px;
    }
    &.block-content-1 {
      width: 232px;
      height: 190px;
      margin-bottom: 32px;
    }
    &.block-content-2 {
      width: 232px;
      height: 190px;
    }
    &.block-content-3 {
      width: 232px;
      height: 412px;
    }
    .block-content-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(63, 46, 43, 0.32);
      display: flex;
      align-items: center;
      padding: 30px;
      h2 {
        font-family: $Roboto;
        font-size: 32px;
        font-weight: bold;
        color: $wh;
        max-width: 330px;
      }
    }
  }
}

.main-container {
  display: flex;
  margin-top: 32px;
  align-items: start;
  .left-main-container,
  .right-main-container {
    width: 100%;
    flex: 1 0;
  }
  .left-main-container {
    padding-right: 16px;
    margin-right: -16px;
    background-color: $white-two;
    div {
      width: 100%;
    }
  }
}

.factory-block {
  background-color: $white-two;
  padding: 25px 38px 0 0;
  h1 {
    font-size: 22px;
    font-weight: bold;
    color: $greyishA;
  }
  p {
    margin-bottom: 0;
  }
}

.factory-list-mobile {
  display: none;
}

.factory-list-full {
  display: block;
}

.factory-list {
  .icon {
    width: 42px;
    height: 42px;
    margin: 32px 0 12px 0;
    &.fl-1 {
      background: url($pathImg+'/icon/fl-1.svg') no-repeat center center;
      background-size: contain;
    }
    &.fl-2 {
      background: url($pathImg+'/icon/fl-2.svg') no-repeat center center;
      background-size: contain;
    }
    &.fl-3 {
      background: url($pathImg+'/icon/fl-3.svg') no-repeat center center;
      background-size: contain;
    }
    &.fl-4 {
      background: url($pathImg+'/icon/fl-4.svg') no-repeat center center;
      background-size: contain;
    }
    &.fl-5 {
      background: url($pathImg+'/icon/fl-5.svg') no-repeat center center;
      background-size: contain;
    }
    &.fl-6 {
      background: url($pathImg+'/icon/fl-6.svg') no-repeat center center;
      background-size: contain;
    }
    &.fl-7 {
      background: url($pathImg+'/icon/fl-7.svg') no-repeat center center;
      background-size: contain;
    }
    &.fl-8 {
      background: url($pathImg+'/icon/fl-8.svg') no-repeat center center;
      background-size: contain;
    }
  }
  .fl-t {
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 32px;
    strong {
      color: #424242;
    }
  }
}
.banners-block{
  max-width: 100%;
  a{
    display: block;
    margin-bottom: 32px;
    img {
      width: 100%;
    }
  }
}
.contact-us-block {
  margin-bottom: 32px;
  a {
    display: block;
    width: 100%;
    background-color: $pale-red;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: $wh;
    transition: $transition-bg;
    &:hover {
      color: $wh;
      background-color: $pale-red-hover;
    }
    span {
      background: url($pathImg+'/icon/phone.svg') no-repeat left center;
      background-size: 18px 18px;
      padding-left: 25px;
    }
  }
}

.mod-blog-list {
  .blog-list-hedarer {
    display: flex;
    a {
      margin-bottom: .5rem;
      margin-left: 16px;
    }
  }
  .blog-content-second {
    position: relative;
    margin-top: 20px;
    .image-block {
      width: 100%;
      height: 150px;
      img {
        width: 100%;
        min-height: 150px;
      }
    }
    .blog-content-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
      display: flex;
      align-items: center;
      padding: 24px;
      .blog-content-h {
        font-size: 17px;
        font-weight: 500;
        color: $wh;
        h4 {
          font-size: 17px;
          font-weight: 500;
        }
      }
      .blog-content-d {
        opacity: 0.72;
        font-size: 12px;
        color: $wh;
      }
    }
  }
  .blog-content-first {
    margin-top: 20px;
    .blog-content-h {
      color: $dull-orange;
      font-weight: 500;
      h4 {
        font-size: 17px;
        margin-bottom: 2px;
      }
    }
    .blog-content-d {
      color: $greyishA;
      opacity: 0.72;
      font-size: 12px;
      margin-bottom: 6px;
    }
    .blog-content-c {
      color: $greyish;
      font-size: 14px;
    }
  }
}

.right-social {
  background-color: $white-two;
  margin-top: 32px;
  padding: 16px;
  a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: $wh;
    line-height: 40px;
    padding: 3px 0 0 30px;
    transition: $transition-bg;
    &:hover {
      color: $wh;
    }
    &.right-social-facebook {
      background-color: #4267b2;
      &:hover {
        background-color: #294f99;
      }
      span {
        background: url($pathImg+'/icon/right-social-facebook.svg') no-repeat left center;
        padding-left: 30px;
      }
    }
    &.right-social-instagram {
      margin-top: 16px;
      background-image: radial-gradient(circle at 0 0, #feda75, #fa7e1f 20%, #d62976 43%, #962fbf 72%, #4f5bd5);
      &:hover {
        background: radial-gradient(circle at 0 0, #feda75, #fa7e1f 20%, #d62976 43%, #962fbf 72%, #4f5bd5), linear-gradient(to bottom, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16));
      }
      span {
        background: url($pathImg+'/icon/right-social-instagram.svg') no-repeat left center;
        padding-left: 30px;
      }
    }
  }
}

.mod-owl {
  margin-top: 32px;
  .owl-container {
    padding: 0 88px;
    margin-top: 38px;
    .owl-carousel {
      .owl-item {
        height: 90px;
        display: flex;
        align-items: center;
        img {
          height: 100%;
          width: inherit;
        }
      }
    }
    .item {
      height: 70px;
    }
    .owl-nav {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -88px;
      right: -88px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      pointer-events: none;
      button {
        width: 56px;
        height: 56px;
        pointer-events: auto;
        &.owl-prev {
          background: url($pathImg+'/icon/left-arrow.svg') no-repeat center center;
          background-size: contain;
          &:hover {
            background: url($pathImg+'/icon/left-arrow-h.svg') no-repeat center center;
            background-size: contain;
          }
          &:active {
            background: url($pathImg+'/icon/left-arrow-a.svg') no-repeat center center;
            background-size: contain;
          }
        }
        &.owl-next {
          background: url($pathImg+'/icon/right-arrow.svg') no-repeat center center;
          background-size: contain;
          &:hover {
            background: url($pathImg+'/icon/right-arrow-h.svg') no-repeat center center;
            background-size: contain;
          }
          &:active {
            background: url($pathImg+'/icon/right-arrow-a.svg') no-repeat center center;
            background-size: contain;
          }
        }
        span {
          display: none;
        }
      }
    }
  }
}

.feedback-block {
  background: $white-two;
  margin: 32px 0 0 0;
  padding: 32px 0 32px 0;
  h2 {
    font-size: 22px;
    font-weight: bold;
    color: $greyishA;
    margin-bottom: 25px;
  }
}

.first-form {
  display: none;
}

@import 'mainpageMobile';
