@media (max-width: 991px) {
  .item-page {
    .page-header {
      .page-header-img {
        min-height: 320px;
      }
    }
    .article-body {
      width: 100%;
    }
  }
}