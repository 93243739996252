.blog-list-page {
  .blog-list-container {
    display: grid;
    grid-template-areas: "l1 l1 l2 l3""l1 l1 l4 l5""l8 l8 l6 l7""l9 l10 l11 l11";

    .blog-content-type1 {
      grid-area: l1;
      margin: 0 32px 32px 0;
    }

    .blog-content-type2 {
      grid-area: l2;
      margin: 0 32px 32px 0;
    }

    .blog-content-type3 {
      grid-area: l3;
      margin: 0 0 32px 0;
    }

    .blog-content-type4 {
      grid-row-start: l4;
      grid-column-start: l6;
      grid-row-end: l6;
      grid-column-end: l7;
      margin: 0 0 32px 0;
    }

    .blog-content-type5 {
      grid-area: l8;
      margin: 0 32px 32px 0;
    }

    .blog-content-type6 {
      grid-area: l9;
      margin: 0 32px 32px 0;
    }

    .blog-content-type7 {
      grid-area: l10;
      margin: 0 32px 32px 0;
    }

    .blog-content-type8 {
      grid-area: l11;
      margin: 0 0 32px 0;
    }

    .blog-content-type1,
    .blog-content-type4 {
      border: 1px solid $white;
      width: 496px;
      height: 406px;

      .text-block {
        padding: 16px 16px;
        color: $greyish;
        font-size: 14px;
        line-height: 16px;
        height: 110px;
        display: flex;
        align-items: center;
      }

      .image-block {
        width: 496px;
        height: 296px;
        position: relative;

        .blog-content-bg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
          display: flex;
          align-items: flex-end;
          padding: 15px;
          z-index: 2;

          .blog-content-h {
            max-width: 360px;
            display: block;
            color: $wh;

            h2 {
              font-size: 17px;
              font-weight: 500;
            }
          }

          .blog-content-d {
            opacity: 0.72;
            font-size: 12px;
            color: $wh;
          }
        }
      }
    }

    .blog-content-type2,
    .blog-content-type3,
    .blog-content-type6,
    .blog-content-type7 {
      border: 1px solid $white;

      &.no-border {
        border: none;
      }

      width: 232px;
      height: 187px;

      .image-block {
        width: 232px;
        height: 187px;
        position: relative;

        .blog-content-bg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
          display: flex;
          align-items: flex-end;
          padding: 15px;
          z-index: 2;

          .blog-content-h {
            max-width: 360px;
            display: block;
            color: $wh;

            h2 {
              font-size: 17px;
              font-size: 17px;
              font-weight: 500;
            }
          }

          .blog-content-d {
            opacity: 0.72;
            font-size: 12px;
            color: $wh;
          }
        }
      }
    }

    .blog-content-type5,
    .blog-content-type8 {
      border: 1px solid $white;
      width: 496px;
      height: 187px;
      padding: 24px;

      .blog-content-h {
        font-size: 17px;
        font-weight: 500;
        color: $dull-orange;

        h2 {
          font-size: 17px;
          font-weight: 500;
        }
      }

      .blog-content-d {
        opacity: 0.72;
        font-size: 12px;
        color: $greyishA;
      }

      .text-block {
        font-size: 14px;
        color: $greyish;
        line-height: 16px;
        padding-top: 16px;
      }
    }
  }
}

@import 'blogMobile';
