.item-page {
  .page-header {
    background-color: $white-two;
    margin-bottom: 20px;
    .page-header-img {
      position: relative;
      min-height: 500px;
      img {
        width: 100%;
      }
      .page-header-bg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
      }
      .page-header-content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        padding: 30px;
        h2 {
          font-size: 30px;
          font-weight: bold;
          color: $wh;
        }
        .date-publish {
          opacity: 0.72;
          font-size: 12px;
          color: $wh;
        }
      }
    }
  }
  .article-body {
    font-size: 14px;
    line-height: 1.71;
    color: $greyish;
    width: 760px;
    h2 {
      font-size: 22px;
      line-height: 24px;
      margin-top: 55px;
      margin-bottom: 16px;
      font-weight: 500;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 14px;
      font-weight: 500;
    }
    h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 25px;
      font-weight: 500;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 22px;
      img {
        max-width: 100%;
      }
    }
    table {
      width: 100%;
      border: 1px #eeeeee solid;
      margin-bottom: 20px;
      thead {
        td {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          padding: 16px 18px;
          p {
            line-height: 16px;
            margin-bottom: 0;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(2n) {
            background-color: rgba(0, 0, 0, 0.02);
          }
          td {
            padding: 16px 18px;
            &:first-child {
              font-size: 14px;
              font-weight: 500;
              line-height: 24px;
              width: 140px;
            }
            line-height: 16px;
            p {
              line-height: 16px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .page-empty-header {
    margin-bottom: 16px;
  }
}

@import 'articleMobile';