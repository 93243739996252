@media (max-width: 767px) {
  .header .navbar {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .header {
    height: 140px;
    .navbar {
      .header-contacts{
        padding-left: 60px;
        padding-top: 10px;
      }
    }
  }
}