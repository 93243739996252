.header {
  height: 108px;
  border-bottom: 1px $white solid;
  margin-bottom: 32px;

  .navbar {
    height: 108px;
    padding: .5rem 0;

    .header-contacts {
      padding-left: 20px;
      font-size: 14px;
      line-height: 18px;
      .phone {
        a {
          color: #433430;
        }
      }

      .email {
        a {
          color: #D95447;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }

    .navbar-brand {
      .logo {
        margin-right: 16px;
        width: 62px;
        height: 62px;
      }

      .logo-text {
        width: 138px;
        height: 27px;
      }
    }

    .navbar-collapse {
      justify-content: flex-end;
      height: 108px;

      .navbar-nav {
        height: 108px;

        >li {
          margin-right: 30px;
          line-height: 108px;
          position: relative;

          &.active {
            >a {
              color: $dull-orange;
            }

            &:after {
              content: '';
              position: absolute;
              width: 24px;
              height: 4px;
              background: $dull-orange;
              display: block;
              left: 50%;
              margin-left: -12px;
              bottom: -2px;
            }
          }

          &.dropdown {
            margin-right: 45px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .dropdown {
    .dropdown-toggle {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 9px;
        height: 6px;
        background: url($pathImg+'/icon/arrow-b.svg') no-repeat center center;
        background-size: contain;
        border: none;
        right: -15px;
        top: 50%;
        margin-top: -3px;
      }
    }

    &.show {
      .dropdown-toggle {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-menu {
      padding: 20px 0 0 0;
      top: 65px;
      left: -49px;
      background: transparent url($pathImg+'/icon/dr.svg') no-repeat center center;
      background-size: 205px 149px;
      border: none;
      width: 205px;
      height: 149px;

      .dropdown-item {
        line-height: 20px;
        padding: 8px 0 8px 30px;
        font-size: 14px;
        white-space: normal;
        width: 200px;

        &.active {
          background-color: transparent;

          a {
            color: $dull-orange;
          }
        }

        &:active {
          background-color: transparent;

          a {
            color: $dull-orange;
          }
        }

        &:hover {
          background-color: transparent;

          a {
            color: $dull-orange;
          }
        }
      }
    }
  }
}

@import 'headerMobile';