@media (max-width: 1199px) {
  .main-first-block {
    .block-content {
      .block-content-bg {
        h2 {
          font-size: 28px;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      &.block-content-0 {
        width: 448px;
        height: 372px;
      }

      &.block-content-1 {
        width: 208px;
        height: 170px;
      }

      &.block-content-2 {
        width: 208px;
        height: 170px;
      }

      &.block-content-3 {
        width: 208px;
        height: 372px;
      }

      .block-content-bg {
        padding: 20px;
      }
    }
  }

  .main-container .left-main-container {
    background: transparent;
  }

  .factory-block {
    padding: 16px;
  }
}

@media (max-width: 991px) {
  .main-first-block {
    .block-content {
      &.block-content-0 {
        width: 328px;
        height: 272px;
      }

      &.block-content-1 {
        width: 148px;
        height: 121px;
      }

      &.block-content-2 {
        width: 148px;
        height: 121px;
      }

      &.block-content-3 {
        width: 148px;
        height: 274px;
      }

      .block-content-bg {
        padding: 20px;

        h2 {
          font-size: 20px;
        }
      }
    }
  }

  .contact-us-block {
    margin-bottom: 16px;

    a {
      font-size: 14px;

      span {
        background-size: 14px 14px;
        padding-left: 18px;
      }
    }
  }

  .mod-blog-list {
    .blog-content-first {
      margin-top: 0;

      .blog-content-h {
        h4 {
          font-size: 14px;
        }
      }

      .blog-content-c {
        font-size: 12px;
      }
    }

    .blog-content-second {
      .blog-content-bg {
        padding: 10px;

        .blog-content-h {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
  }

  .right-social {
    padding: 0;
    background: transparent;

    a {
      font-size: 14px;
      padding-left: 10px;

      &.right-social-facebook {
        span {
          background-size: 17px 17px;
          padding-left: 25px;
        }
      }

      &.right-social-instagram {
        span {
          background-size: 17px 17px;
          padding-left: 25px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .mod-owl {
    display: none;
  }

  a.btn-lnk,
  span.btn-lnk {
    &.btn-lnk-w {
      .lnk {
        padding: 3px 10px;
        width: 92px;
        line-height: 20px;
      }

      .icon {
        width: 0;
      }
    }
  }

  .main-first-block {
    .block-content {
      .btn-lnk-w {
        .lnk {
          width: 95px;
          padding: 5px 10px;
          color: $greyish;
          background-color: $wh;
        }

        .icon {
          width: 0;
        }
      }

      &:hover {
        .btn-lnk-w {
          .lnk {
            background-color: $wh;
            width: 95px;
            padding: 0 10px;
            color: $greyish;
          }
        }
      }
    }
  }

  .right-social a {
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    &.right-social-facebook {
      span {
        background-size: 18px 18px;
        padding-left: 25px;
      }
    }
  }

  .contact-us-block {
    margin-top: 32px;
    margin-bottom: 32px;

    a {
      font-size: 16px;
      font-weight: 500;

      span {
        background-size: 18px 18px;
        padding-left: 25px;
      }
    }
  }

  .factory-list-mobile {
    margin-top: 1rem;
    display: block;

    &.factory-list {
      .item {
        .icon {
          margin: 13px auto;
        }

        .fl-t {
          text-align: center;
          margin: 0 auto;
          max-width: 150px;
        }
      }

      .owl-dots {
        margin-top: 32px;
        text-align: center;

        .owl-dot {
          width: 8px;
          height: 8px;
          background: #b9b4b2;
          margin-right: 8px;
          border-radius: 50%;

          &:last-of-type {
            margin-right: 0;
          }

          &.active {
            background: $dull-orange;
          }
        }
      }
    }
  }

  .factory-list-full {
    display: none;
  }

  .main-first-block {
    .block-content {
      .block-content-bg {
        padding: 30px;

        h2 {
          font-size: 32px;
        }
      }

      &.block-content-0 {
        width: 508px;
        height: 420px;
        margin-bottom: 32px;
      }

      &.block-content-1 {
        width: 238px;
        height: 194px;
      }

      &.block-content-2 {
        width: 238px;
        height: 194px;
      }

      &.block-content-3 {
        width: 238px;
        height: 420px;
      }
    }
  }
}

@media (max-width:575px) {
  .container {
    padding-right: 8px;
    padding-left: 8px;
  }

  .row {
    margin-right: -8px;
    margin-left: -8px;
  }

  .col-16 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .factory-block {
    h1 {
      font-size: 20px;
    }
  }

  .contact-us-block {
    a {
      line-height: 50px;
    }
  }

  .right-social a {
    padding: 6px 0 3px 0;
  }

  .main-first-block {
    .block-content {
      &.block-content-0 {
        width: 100%;
        height: 304px;
        margin-bottom: 8px;
      }

      &.block-content-1 {
        width: 100%;
        height: 304px;
        margin-bottom: 8px;
      }

      &.block-content-2 {
        width: 100%;
        height: 304px;
        margin-bottom: 8px;
      }

      &.block-content-3 {
        width: 100%;
        height: 304px;
        margin-bottom: 0;
      }
    }
  }
}
