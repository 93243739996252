@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

html {
  font-size: 16px;

  body {
    font-family: $Roboto;
    font-size: 0.875rem;
    color: $greyish;
    background-color: $wh;
    min-width: 320px;

    .body {
      min-height: calc(100vh - 160px);
    }

    // .container {
    //   max-width: 1056px;
    // }
    a {
      color: $greyishA;

      &:hover {
        color: $greyish;
        text-decoration: none;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: bold;
      color: $greyishA;
    }

    p {
      color: $greyish;
      font-size: 14px;

      &.p17 {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}

a.btn-lnk,
span.btn-lnk {
  display: flex;
  align-items: center;

  .lnk {
    overflow: hidden;
    padding: 0;
    width: 1px;
    transition: all 0.3s ease-out;
  }

  .icon {
    display: block;
    width: 13px;
    height: 11px;
    margin-left: 24px;
    transition: all 0.3s ease-out;
  }

  &:hover {
    .lnk {
      padding: 0 10px;
      width: 92px;
    }

    .icon {
      width: 0;
    }
  }

  &.btn-lnk-w {
    line-height: 27px;

    .lnk {
      background-color: $wh;
    }

    .icon {
      background: url($pathImg+'/icon/btn-lnk-w.svg') no-repeat center center;
      background-size: contain;
    }
  }

  &.btn-lnk-b {
    line-height: 16px;
    color: #ffffff;
    font-size: 12px;

    &:hover {
      .lnk {
        width: 75px;
        padding: 0 5px;
      }

      .icon {
        width: 0;
      }
    }

    .lnk {
      background-color: $greyishA;
    }

    .icon {
      background: url($pathImg+'/icon/btn-lnk-b.svg') no-repeat center center;
      background-size: contain;
      width: 10px;
      height: 8px;
      margin-left: 16px;
    }
  }
}

.btn-arrow {
  &:after {
    content: '';
    width: 8px;
    height: 7px;
    display: inline-block;
  }

  &.btn-arrow-do {
    &:after {
      background: url($pathImg+'/icon/arrow-do.svg') no-repeat center center;
      background-size: contain;
      width: 20px;
    }
  }

  &.btn-arrow-wh {
    &:after {
      background: url($pathImg+'/icon/arrow-wh.svg') no-repeat center center;
      background-size: 11px 9px;
      width: 20px;
      height: 13px;
    }
  }
}

button {
  &:focus {
    outline: none;
  }
}

.form-control {
  background-color: $wh;
  border: 1px $white solid;
  border-radius: 0;
  padding: 9px 12px;
  height: 44px;
  color: $greyish;
  font-size: 14px;

  &:hover {
    border-color: #ffcfba;
  }

  &:focus {
    background-color: $wh;
    border-color: $dull-orange;
    outline: 0;
    box-shadow: none;
  }
}

.rsform-block-send {
  justify-content: center;
  display: flex;

  &.form-group {
    margin-bottom: 0;
  }
}

.form-group {
  margin-bottom: 16px;

  .invalid-feedback {
    margin-top: 0;
    display: block;
    height: 16px;
  }
}

#content {
  width: 100%;
}

.btn {
  &.btn-primary {
    color: $dull-orange;
    background-color: $wh;
    border-color: $dull-orange;
    border-radius: 0;
    padding: 6px 32px;
    font-size: 16px;
    font-weight: 500;

    &:not(:disabled):not(.disabled):hover {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    }

    &:not(:disabled):not(.disabled):active {
      color: $dull-orange;
      background-color: $wh;
      border-color: $dull-orange;
      border-radius: 0;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }
}

@media (max-width:575px) {
  .btn {
    &.btn-primary {
      min-width: 200px;
    }
  }
}

.breadcrumb {
  background: none;
  font-size: 11px;
  padding: 0;
  color: $dull-orange;

  a {
    color: #c4bfbf;
  }

  .divider {
    color: #c4bfbf;
    display: inline-block;
    padding-right: 3px;
  }
}

.col-33 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 16px;
  padding-left: 16px;
}

@media (min-width: 1200px) {
  .col-xl-33 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (min-width: 992px) {
  .col-lg-33 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (min-width: 768px) {
  .col-md-33 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

textarea {
  resize: none;
}
