.repair-page {
  margin-top: -32px;

  .repair-page-header {
    background: $white-two;

    .repair-page-header-block {
      .row-hor {
        background: url('/images/repair-1.jpg') no-repeat center center;
        background-size: cover;
        padding: 64px;
        height: 600px;

        h1 {
          font-size: 48px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 40px;
        }

        .repair-page-header-text {
          color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          line-height: 1.5;
          margin-bottom: 45px;
        }

        .repair-button {
          width: 310px;
          height: 70px;
          background: url($pathImg+ '/icon/turnkey-button.svg') no-repeat left center;
          background-size: cover;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          color: $dull-orange;
          display: inline-block;
          text-align: center;
          line-height: 65px;
        }
      }
    }
  }

  .repair-page-sec1 {
    &-about {
      background: $white-two;
      height: 100%;
      display: flex;
      align-items: center;

      >div {
        width: 100%;
      }

      .fl-repair-icon {
        height: 72px;
        background: url($pathImg+ '/icon/fl-repair.svg') no-repeat center center;
        background-size: contain;
      }

      h3 {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        margin-top: 20px;
        color: $greyishA;
      }
    }

    &-text {
      padding-top: 28px;
      padding-bottom: 28px;
      color: $greyish;
      display: flex;
      align-items: center;
      min-height: 307px;

      ul {
        padding-left: 0;
        list-style: none;

        li {
          font-size: 17px;
          line-height: 1.41;
          margin-bottom: 20px;

          &::before {
            content: '';
            width: 9px;
            height: 9px;
            background: url($pathImg+ '/icon/hor-enum2-h.svg') no-repeat left center;
            background-size: contain;
            display: inline-block;
            padding-right: 25px;
          }
        }
      }
    }
  }

  .some-class {
    min-height: 50vh;
    background: transparent;
  }

  .repair-page-sec2 {
    padding: 32px 0;
    margin-bottom: 2rem;

    .fl-repair2-icon {
      height: 48px;
      background: url($pathImg+ '/icon/fl-repair-2.svg') no-repeat center center;
      background-size: contain;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
    }
  }

  .repair-page-sec3 {
    margin-bottom: 2rem;

    h3 {
      font-size: 22px;
      font-weight: bold;
      color: $greyishA;
      margin-bottom: 3rem;
    }

    .row {
      .sec3-block {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        border: 1px solid #ffffff;
        height: 144px;
        margin-bottom: 1rem;
        display: flex;
        cursor: pointer;
        padding: 20px;

        &.checked {
          border: 1px solid $dull-orange;

          .icon {

            &.r-1,
            &.r-2,
            &.r-3,
            &.r-4,
            &.r-5,
            &.r-6 {
              background: url($pathImg+ '/icon/r-c.svg') no-repeat center center;
              background-size: contain;
            }
          }
        }

        &-title {
          font-size: 22px;
          font-weight: bold;
          color: $dull-orange;
          margin-bottom: 15px;
        }

        &-text {
          font-size: 17px;
          line-height: 20px;
        }

        .icon {
          width: 25px;
          height: 25px;
          margin: 5px 10px 0 5px;

          &.r-1 {
            background: url($pathImg+ '/icon/r-1.svg') no-repeat center center;
            background-size: contain;
          }

          &.r-2 {
            background: url($pathImg+ '/icon/r-2.svg') no-repeat center center;
            background-size: contain;
          }

          &.r-3 {
            background: url($pathImg+ '/icon/r-3.svg') no-repeat center center;
            background-size: contain;
          }

          &.r-4 {
            background: url($pathImg+ '/icon/r-4.svg') no-repeat center center;
            background-size: contain;
          }

          &.r-5 {
            background: url($pathImg+ '/icon/r-5.svg') no-repeat center center;
            background-size: contain;
          }

          &.r-6 {
            background: url($pathImg+ '/icon/r-6.svg') no-repeat center center;
            background-size: contain;
          }
        }

        &:hover {
          border: solid 1px rgba(217, 116, 71, 0.32);
        }
      }
    }

    .action-block {
      text-align: center;
      margin-top: 2rem;
    }
  }
}

.rent-page {
  margin-top: -32px;

  .services-sec {
    margin-top: 20px;

    h2 {
      font-size: 22px;
      font-weight: bold;
      color: $greyishA;
      margin-bottom: 25px;
    }
  }

  .rent-page-sec {
    margin: 28px 0;

    .rent-text {
      font-size: 17px;
      color: $greyish;
    }
  }

  .type-machines {
    .type-machine {
      h2 {
        font-size: 22px;
        font-weight: bold;
        color: $greyishA;
        margin-bottom: 25px;
      }

      .type-machines-img {
        height: 340px;
        margin-bottom: 32px;
      }

      &.prof {
        .type-machines-img {
          background: url('/images/rent-2.jpg') no-repeat center center;
          background-size: cover;
        }
      }

      &.auto {
        .type-machines-img {
          background: url('/images/rent-3.jpg') no-repeat center center;
          background-size: cover;
        }
      }
    }
  }

  .rent-page-header {
    background: $white-two;

    .rent-page-header-block {
      .row-hor {
        background: url('/images/rent-1.jpg') no-repeat center center;
        background-size: cover;
        padding: 64px;
        height: 600px;

        h1 {
          font-size: 48px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 40px;
        }

        .turnkey-page-header-text {
          color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          line-height: 1.5;
          margin-bottom: 45px;
        }

        .rent-button {
          width: 310px;
          height: 70px;
          background: url($pathImg+ '/icon/turnkey-button.svg') no-repeat left center;
          background-size: cover;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          color: $dull-orange;
          display: inline-block;
          text-align: center;
          line-height: 65px;
        }
      }
    }
  }

  .flex-table {
    display: block;
    width: 100%;
    margin-bottom: 20px;

    .flex-tr {
      display: flex;
      flex-wrap: nowrap;
      border-top: 1px solid #eeeeee;

      &:last-child {
        border-bottom: 1px solid #eeeeee;

        .flex-td {
          background-color: rgba(0, 0, 0, 0.02);
        }
      }
    }

    .flex-td {
      width: 100%;
      flex: 0 1 auto;
      padding: 22px;
      border-left: 1px solid #eeeeee;

      &:last-child {
        border-right: 1px solid #eeeeee;
      }
    }

    .flex-tr {
      &.flex-tr-header {
        .flex-td {
          font-weight: 500;
        }
      }
    }

    .flex-tr {
      .flex-td {
        &:first-child {
          flex: 0 0 275px;
          display: flex;
          align-items: center;
          font-weight: 500;
        }
      }
    }

    .condition {
      font-size: 14px;
      color: $greyish;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        margin-bottom: 5px;
      }

      &-value {
        &::before {
          content: '';
          width: 9px;
          height: 9px;
          background: url($pathImg+ '/icon/hor-enum2-h.svg') no-repeat left center;
          background-size: contain;
          display: inline-block;
          padding-right: 25px;
        }
      }
    }
  }
}

.turnkey-page {
  margin-top: -32px;

  .turnkey-page-header {
    background: $white-two;

    .turnkey-page-header-block {
      .row-hor {
        background: url('/images/turnkey-1.jpg') no-repeat center center;
        background-size: cover;
        padding: 64px;
        height: 600px;

        h1 {
          font-size: 48px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 40px;
        }

        .turnkey-page-header-text {
          color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          line-height: 1.5;
          margin-bottom: 45px;
        }

        .turnkey-button {
          width: 310px;
          height: 70px;
          background: url($pathImg+ '/icon/turnkey-button.svg') no-repeat left center;
          background-size: cover;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          color: $dull-orange;
          display: inline-block;
          text-align: center;
          line-height: 65px;
        }
      }
    }
  }

  .turnkey-photo-list {
    .turnkey-photo {
      width: 232px;
      height: 232px;

      a {
        display: block;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .turnkey-text {
    color: $greyish;
    font-size: 17px;
    padding: 28px 0;
  }
}

.horeca-page {
  margin-top: -32px;

  .horeca-page-header {
    background: $white-two;

    .horeca-page-header-block {
      .row-hor {
        background: url('/images/h-1.jpg') no-repeat center center;
        background-size: cover;
        padding: 64px;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .horeca-page-header-text {
          font-size: 18px;
          color: #ffffff;
          line-height: 30px;
        }

        .enums-horeca-page-header {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          line-height: normal;

          >div {
            padding-bottom: 20px;
            padding-left: 37px;

            &::before {
              content: '';
              width: 16px;
              height: 16px;
              background: url($pathImg+ '/icon/hor-enum-h.svg') no-repeat left center;
              background-size: contain;
              display: inline-block;
              padding-right: 37px;
              margin-left: -37px;
            }
          }
        }
      }
    }
  }

  .horeca-button {
    padding: 0 30px;
    line-height: normal;
    background: url($pathImg+ '/icon/hor-button.png') no-repeat left center;
    background-size: contain;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    display: inline-block;
    height: 55px;
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 48px;
    font-weight: bold;
    line-height: 0.42;
    color: #ffffff;
  }

  .horeca-page-sec1 {
    &-about {
      background: $white-two;
      height: 100%;
      display: flex;
      align-items: center;

      >div {
        width: 100%;
      }

      .fl-h-icon {
        height: 72px;
        background: url($pathImg+ '/icon/fl-h.svg') no-repeat center center;
        background-size: contain;
      }

      h3 {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        margin-top: 20px;
        color: $greyishA;
      }
    }

    &-text {
      padding-top: 28px;
      padding-bottom: 28px;
      color: $greyish;

      ul {
        padding-left: 0;
        list-style: none;

        li {
          font-size: 17px;
          line-height: 1.41;

          &::before {
            content: '';
            width: 9px;
            height: 9px;
            background: url($pathImg+ '/icon/hor-enum2-h.svg') no-repeat left center;
            background-size: contain;
            display: inline-block;
            padding-right: 25px;
          }
        }
      }

      p {
        font-size: 17px;
        line-height: 1.41;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .horeca-page-sec2 {
    padding-top: 60px;

    h3 {
      font-size: 22px;
      font-weight: bold;
      color: $greyishA;
      margin-bottom: 1rem;
    }

    .row {
      >div {
        margin-bottom: 32px;
        height: 320px;
      }
    }

    .block-hotel {
      position: relative;
      height: 100%;

      .desc {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        transition: all .6s ease-in-out;
        opacity: 0;
        color: #ffffff;

        .text {
          max-width: 60%;
          margin: 0 auto;
          font-size: 16px;
        }
      }

      &:hover {
        .desc {
          opacity: 1;
          background: rgba(66, 66, 66, 0.72);
        }

        .info {
          opacity: 0;
        }
      }

      .block-image {
        padding: 20px;
        height: 100%;

        .info {
          transition: all .6s ease-in-out;
          background-color: rgba(0, 0, 0, 0.699);
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          padding: 15px;
          border: 1px solid #ffffff;
          display: inline-block;
        }

        &-1 {
          background: url('/images/h-2.jpg') no-repeat center center;
          background-size: cover;

          .info {
            max-width: 520px;
          }
        }

        &-2 {
          background: url('/images/h-3.jpg') no-repeat center center;
          background-size: cover;

          .info {
            max-width: 404px;
          }
        }

        &-3 {
          background: url('/images/h-4.jpg') no-repeat center center;
          background-size: cover;

          .info {
            max-width: 290px;
          }
        }

        &-4 {
          background: url('/images/h-5.jpg') no-repeat center center;
          background-size: cover;

          .info {
            max-width: 576px;
          }
        }

        &-5 {
          background: url('/images/h-6.jpg') no-repeat center center;
          background-size: cover;

          .info {
            max-width: 230px;
          }
        }

        &-6 {
          background: url('/images/h-7.jpg') no-repeat center center;
          background-size: cover;

          .info {
            max-width: 263px;
          }
        }

        &-7 {
          background: url('/images/h-8.jpg') no-repeat center center;
          background-size: cover;

          .info {
            max-width: 125px;
          }
        }
      }
    }
  }

  .horeca-page-sec3 {
    h3 {
      color: $dull-orange;
      font-size: 22px;
      margin-bottom: 1rem;
    }

    .row {
      .sec3-block {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        height: 178px;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: .5rem;

        .h-t {
          font-size: 1rem;
          text-align: center;
        }

        .icon {
          width: 40px;
          height: 40px;
          margin-bottom: 1rem;

          &.h-1 {
            background: url($pathImg+ '/icon/h-1.svg') no-repeat center center;
            background-size: contain;
          }

          &.h-2 {
            background: url($pathImg+ '/icon/h-2.svg') no-repeat center center;
            background-size: contain;
          }

          &.h-3 {
            background: url($pathImg+ '/icon/h-3.svg') no-repeat center center;
            background-size: contain;
          }

          &.h-4 {
            background: url($pathImg+ '/icon/h-4.svg') no-repeat center center;
            background-size: contain;
          }

          &.h-5 {
            background: url($pathImg+ '/icon/h-5.svg') no-repeat center center;
            background-size: contain;
          }

          &.h-6 {
            background: url($pathImg+ '/icon/h-6.svg') no-repeat center center;
            background-size: contain;
          }
        }
      }
    }
  }
}

.modal-form {
  .modal-content {
    background: #ffffff;

    .modal-header {
      border-bottom: none;
      padding-bottom: 0;
    }

    .modal-body {
      padding: 0 2rem 2rem 2rem;
    }
  }

  .block-form {
    h2 {
      font-size: 17px;
      font-weight: bold;
      text-align: center;
      color: $greyishA;
      margin-bottom: 2rem;
    }

    .rsform-block-type_error {
      .formControls {
        display: flex;
        flex-wrap: wrap;
      }

      .form-check-inline {
        display: none;

        &.is-checked {
          display: block;

          .form-check-label {
            border: 1px solid $white;
            cursor: pointer;
            line-height: 27px;
            font-style: italic;
            font-size: 14px;
            font-style: italic;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 1rem;

            &::after {
              content: '';
              background: url($pathImg+ '/icon/close.svg') no-repeat center center;
              background-size: contain;
              width: 7px;
              height: 7px;
              display: inline-block;
              margin-left: 8px;
            }

            input {
              display: none;
            }
          }
        }
      }
    }
  }
}

@import 'servicesMobile';
