.product-page {
  .modal-content {
    &.step-finish {
      .modal-body {
        .modal-title {
          display: none;

          &.modal-title-step-finish {
            display: block;
          }
        }

        .order-form {
          display: none;
        }

        .order-form-img {
          display: flex;
        }
      }

      .modal-footer {
        display: none;

        &.modal-footer-step-finish {
          display: block;
        }
      }
    }

    background: #ffffff;

    .modal-footer {
      border-top: none;
      flex-direction: column;
      padding-top: 0;
      margin-bottom: 40px;

      &.modal-footer-step-finish {
        display: none;
      }

      .modal_text {
        font-size: 12px;
        font-style: italic;
        line-height: 2;
        color: #8f8f8f;
        margin: 17px 0 0 0;
      }

      .btn-close {
        display: block;
        margin: 0 auto;
      }
    }

    .modal-header {
      padding: 1rem 1rem 0 1rem;
      border-bottom: none;
    }

    .modal-body {
      padding-bottom: 0;

      .formValidation {
        display: none;
      }

      .is-invalid {
        +.invalid-feedback {
          .formValidation {
            display: block;
          }
        }
      }

      .modal-title {
        font-size: 17px;
        font-weight: bold;
        max-width: 360px;
        margin: 0 auto;
        text-align: center;

        &.modal-title-step-finish {
          display: none;
        }

        .perfect {
          color: $dull-orange;
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }

      .order-form {
        width: 456px;
        margin: 0 auto;
        margin-top: 30px;
      }

      .order-form-img {
        display: flex;
        justify-content: center;
        display: none;

        div {
          width: 150px;
          height: 207px;
          background: url($pathImg+'/icon/order-form-img.svg') no-repeat center center;
          margin: 25px 0 40px 0;
        }
      }
    }
  }

  .product-block-description {
    font-size: 14px;
    line-height: 1.71;
    color: $greyish;
    margin-top: 25px;
  }

  .product-main-block {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    .image-block-border {
      margin-right: 32px;

      .image-block {
        border: 1px solid $white;

        img {
          max-width: 320px;
        }
      }
    }

    .action-block {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .action-for-cart {
        margin-bottom: 24px;
      }

      h1 {
        color: $greyishA;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .prod_attributes {
        margin-bottom: 25px;

        .row-fluid {
          &:last-child {
            margin-top: 13px;
          }
        }

        .attributes_title {
          font-size: 14px;
          line-height: 1.71;
          color: #8f8f8f;
        }
      }

      .prod_buttons {
        display: flex;
        justify-content: flex-start;

        .summary_price {
          margin-left: 32px;
        }

        .prod_qty_input {
          border: 1px solid $white;
          display: flex;
          justify-content: flex-start;

          .plus {
            width: 32px;
            height: 32px;
            background: $white url($pathImg+'/icon/plus.svg') no-repeat center center;
            background-size: 15px 15px;
            cursor: pointer;
          }

          .minus {
            width: 32px;
            height: 32px;
            background: $white url($pathImg+'/icon/minus.svg') no-repeat center center;
            background-size: 15px 15px;
            cursor: pointer;
          }

          .qty {
            width: 68px;

            input {
              width: 100%;
              border: none;
              line-height: 30px;
              text-align: center;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}

.input_type_radio {
  width: 150px;
  display: inline-block;
  vertical-align: top;

  &:first-child {
    width: 90px;
  }

  label {
    font-size: 14px;
    line-height: 1.71;
    color: #8f8f8f;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0px 0 0 5px;
  }

  input+label {
    position: relative;
    padding: 0 0 0 20px;
    cursor: pointer;
  }

  input+label:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid $dull-orange;
    border-radius: 50%;
    background: #FFF;
  }

  input+label:after {
    content: '';
    position: absolute;
    top: 7px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $dull-orange;
    opacity: 0;
    transition: .2s;
  }

  input:checked+label {
    color: $greyishA;
  }

  input:checked+label:after {
    opacity: 1;
  }
}

#block_attr_sel_2 {
  .input_type_radio {
    width: 50%;
  }
}

.block_price {
  background-color: $dull-orange;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 4px 22px;
  display: inline-block;

  .full_number {
    font-size: 17px;
  }
}

.hashtags-list {
  display: flex;

  .hashtag {
    border: 1px solid $white;
    font-size: 14px;
    font-style: italic;
    color: $greyishA;
    line-height: 27px;
    padding: 0 12px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.extra_fields_block {
  margin-bottom: 32px;

  .list_specifications {
    display: flex;
    flex-wrap: wrap;

    &.list_specifications-small {
      .block_specifications {
        width: 100%;
      }
    }

    .block_specifications {
      margin-top: 30px;
      width: 50%;

      .specifications_header {
        color: $greyishA;
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .block_specifications_item {
        display: flex;

        .block_specifications_item_title {
          width: 110px;
          text-align: right;
          color: #8f8f8f;
          margin-right: 5px;
          line-height: 25px;
        }

        .block_specifications_item_value {
          line-height: 25px;

          .list_stars {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 7px;

            >div {
              margin-right: 8px;

              &:last-child {
                margin-right: 0;
              }
            }

            .star-r {
              width: 10px;
              height: 10px;
              background: url($pathImg+'/icon/star-r.svg') no-repeat center center;
            }

            .star {
              width: 10px;
              height: 10px;
              background: url($pathImg+'/icon/star.svg') no-repeat center center;
            }
          }
        }
      }
    }
  }
}

.datac-one {
  display: none;
}

.top-product-block {
  .top-product-header {
    margin-top: 2rem;
  }

  .top-product-list {
    &.container {
      padding-left: 0;
      padding-right: 0;
    }

    .top-item {
      margin-bottom: 1rem;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .product_image {
        width: 100%;
        background: $white-two;

        a {
          display: block;

          img {
            width: 100%;
          }
        }
      }

      .category_name {
        background: $dull-orange;

        a {
          display: block;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          line-height: 35px;
        }
      }

      .product_name {
        font-size: 17px;
        font-weight: 500;
        color: $greyish;
        margin-top: 12px;
      }

      .product_desc {
        margin-top: 12px;
      }
    }
  }
}

.related_container {
  .related_header {
    color: $greyishA;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .related_list {
    .related_item {
      width: 100%;
      max-width: 360px;
      margin-bottom: 32px;

      .product_image {
        width: 100%;
        border: 1px solid $white;

        a {
          display: block;
          width: 100%;

          img {
            width: 100%;
          }
        }
      }

      .product_name {
        font-size: 17px;
        font-weight: 500;
        color: $greyish;
        margin-top: 12px;
      }
    }
  }
}

@import 'joomproductMobile';
