$grid-columns: 16;
$grid-gutter-width: 32px;
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1056px) !default;
$Roboto:'Roboto',
sans-serif;
$pathImg: '../images';
$greyish: #424242;
$greyishA: #433430;
$wh: #ffffff;
$white: #ececec;
$white-two: #fafafa;
$pale-red: #d95447;
$pale-red-hover:#bf382c;
$dull-orange: #d97447;
$warm-grey:#8f8683;
$transition-bg: all .2s ease-in-out;