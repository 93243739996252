.footer {
  height: 160px;
  background-color: $greyishA;
  .footer-container {
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-container-right {
      font-size: 12px;
      color: $warm-grey;
    }
    .footer-container-left {
      display: flex;
      .navbar-brand {
        display: block;
      }
      .unp {
        p {
          font-size: 12px;
          color: $warm-grey;
          margin-bottom: 0;
          line-height: 14px;
        }
        a {
          font-size: 12px;
          color: $wh;
          display: block;
          margin-top: 16px;
        }
      }
    }
  }
}
.footer-menu-container {
  display: none;
}
@import 'footerMobile';