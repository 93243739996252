@media (max-width: 1200px) {
  .blog-list-page {
    .blog-list-container {
      display: flex;
      flex-wrap: wrap;
      margin-right: -16px;
      margin-left: -16px;
      .blog-content-type {
        min-height: 1px;
        padding-right: 16px;
        padding-left: 16px;
        margin: 0 0 32px 0;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        height: inherit;
        border: none;
        .image-block {
          width: 100%;
        }
        .text-block {
          border: 1px solid #ececec;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .blog-list-page {
    .blog-list-container {
      margin-right: -8px;
      margin-left: -8px;
      .blog-content-type {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }
}